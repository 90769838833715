import React from 'react';
import './footer.css';
import Gf from '../Footer/GrayFooter/grayFooter';


const footer = ()=>{

if(window.screen.width > 440){
    
    return (

        <div id="footer">
            <Gf />

            <div id= "fDevContainer"> 
                <div id="fBlock1">
                    <img src={require("../../assests/images/logos/wlFooter.png")} id="footerLogo" alt=""/>
                </div>

                <div id="fBlock2">
                    <div id="b2Header">INVESTMENT & DEVELOPMENT</div>
                    <div id="b2Sub">701 E. 83rd Avenue<br />Merrivllville, Indiana 46410</div>
                </div>

                <div id="fBlock3">
                    <div id="fBtns">
                        <a href="https://www.whitelodging.com/"><div id="b3Header1">WL HOMEPAGE</div></a>
                        <a href="https://www.whitelodging.com/services/#investment-development"><div id="b3Header2" >INVESTMENT & DEVELOPMENT</div></a>
                    </div>
                    
                    <div id="ftext">
                        <div className="b3Sub1">®2020 White Lodging Services Inc.  All rights reserved</div>
                    </div>
                </div>

                
            </div>
        </div>
    )

} else {

    return (
    <div id="footer">
    <Gf />

    <div id= "fDevContainer"> 
        <div id="fBlock1">
            <img src={require("../../assests/images/logos/wlLogoWhite.png")} id="footerLogo" alt=""/>
       
        </div>
        <div id="fBlock2">
            <div id="b2Header">INVESTMENT & DEVELOPMENT</div>
            <div id="b2Sub">701 E. 83rd Avenue<br />Merrivllville, Indiana 46410</div>
        </div>

        <div id="fBlock3">
            <div id="fBtns">
                <a href="https://www.whitelodging.com/"><div id="b3Header1">WL HOMEPAGE</div></a>
                <a href="https://www.whitelodging.com/services/#investment-development"><div id="b3Header2" >INVESTMENT & DEVELOPMENT</div></a>
            </div>
            <div id="ftext">
                <div className="b3Sub1">®2020 White Lodging Services Inc.  All rights reserved</div>
            </div>
        </div>

        
    </div>
</div>
    )  
}

}




export default footer;
