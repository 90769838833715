import React, { Component } from 'react';
import './signin.css';
import { withRouter } from 'react-router-dom';
import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { auth } from '../../firebase';





const SignInPage = ({ history }) =>
  <div>
      
    <SignInForm history={history} />
    
  </div>

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,

};



class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const {
      email,
      password,
    } = this.state;

    const {
      history,
    } = this.props;

    auth.doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }));
        history.push(
          {
            pathname:'/', state:{email: email}

          }
          
        );
      })



      .catch(error => {
        this.setState(updateByPropertyName('error', error));
      });

    event.preventDefault();


  }

  render() {
    const {
      email,
      password,
      error,
    } = this.state;

    const isInvalid =
      password === '' ||
      email === '';

    return (

      <div id="fsContainer">
        <div id="sContainer" className="container">

    
       
          
          <video
             src={require('../../assests/videos/wlvideofs.mp4')} type="video/mp4" id="bg-vid" playsInline={true} autoPlay={true} muted={true} loop={true} 
            poster="../../assests/images/mainBackground.jpg"
            >
             
          </video>
          
          <div id="signInFormContainer">
          <div id="mainLogoContainer">
            <img id="mainLogo" src ={require('../../assests/images/logos/wlFullLogo.png')} alt="White Lodging Logo" />
        
          </div>  
          {/* <Title color="white-text" title="INVESTMENT & DEVELOPMENT" /> */}
          <form onSubmit={this.onSubmit} id="signInForm">
          
            <input
            className="input-field white-text bold z-depth-3"
              value={email}
              onChange={event => this.setState(updateByPropertyName('email', event.target.value))}
              type="text"
              placeholder="Email Address"
            />
            <input
            className="input-field white-text"
              value={password}
              onChange={event => this.setState(updateByPropertyName('password', event.target.value))}
              type="password"
              placeholder="Password"
            />
            
            <button id='signIn-btn' className="btn transparent z-depth-0" disabled={isInvalid}  type="submit">
              Sign In
            </button>
            
            { error && <p className="red-text">{error.message}</p> }
            <PasswordForgetLink />
            <SignUpLink />

            
          </form>
          </div>
        </div>
     
              {/* </div>
            </div>
          </div>
   
        </div> */}
        
      </div> 

      
    );
  }
}

export default withRouter(SignInPage);

export {
  SignInForm,
};
