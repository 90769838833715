import React from 'react';
import './projectTeam.css';






const projectTeam =(props)=>{
    return (
        


        <div id="projectTeamContainer" >
            
    <span id="tm"><h3>PROJECT TEAM</h3></span>
        <ul className="teamDesc">
            {props.teamMembers.map(item =>{
                return(<li className='teamName'>{item}</li>)
            }    
            )}
        </ul>
                {/* <div className="boldText2" id='ptWl'><h4>WHITE LODGING SERVICES</h4></div>
                <div id="spacerDiv">
                    <ul className="teamDesc">
                        <li className='teamName'>Russ Louderback </li>
                        <li className='teamName'>Dustin Louderback</li>
                        <li className='teamName'>Adam Estes</li>
                    </ul>
                </div>
            <div className="boldText2" id='con'><h4>DPR CONSTRUCTION</h4></div>
            <ul className="teamDesc">
                    <li className='teamName'>Tim Wuesfield</li>
                    <li className='teamName'>Matt Murphy</li>
                </ul> */}

      </div>   
     
    )}

export default projectTeam;