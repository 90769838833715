import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header/header';
import Title from '../Title/title';
import { auth } from '../../firebase';
import * as routes from '../../constants/routes';
//import Divider from '../Divider/divider';
import './passForget.css';

const PasswordForgetPage = () =>
  <div>
    <PasswordForgetForm />
  </div>

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email } = this.state;

    auth.doPasswordReset(email)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }));
      })
      .catch(error => {
        this.setState(updateByPropertyName('error', error));
      });

    event.preventDefault();
  }

  render() {
    window.scrollTo(0, 0)
    const {
      email,
      error,
    } = this.state;

    const isInvalid = email === '';

    return (
      // <div id="container" className='container white'>
      // <Divider siteTitle="RESET PASSWORD" titleColor="blackTitle" id="divider" divDivider="divDividerPW" />
      // <Header style="wlLogoNonAlt" color="white-text"/>
      //   <div className="row">
      //     <div className="col2 s12 m6" id='passResetBox'>
      <div id="editContainerPF" className='container white'>
      <Header style="wlLogoNonAlt" color="white-text" />
      <div class="row">
        <div class="col2 s12 m6">
          <div class="card z-depth-4">
            <div class="card-content">
              <Title color="titleW" title="RESET PASSWORD" />
      
                <form onSubmit={this.onSubmit}>
                <div>
                        <label className="labelDiv">
                          <span className="labelText">Email Address</span>
                        </label>
                      </div>
             
                  <input className="input-field white-text gray lighten-2"
                    value={this.state.email}
                    onChange={event => this.setState(updateByPropertyName('email', event.target.value))}
                    type="text"
                    placeholder=" ex. you@Gmail.com"
                  />
              
                  <button className='btn pink lighten-1 z-depth-0'
                    disabled={isInvalid} type="submit">
                    Reset My Password
                  </button>

                  { error && <p>{error.message}</p> }
                </form>
   </div>
   </div>
          </div>
        </div>
    </div>
    );
  }
}

const PasswordForgetLink = () =>
  <p>
    <Link to={routes.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>

export default PasswordForgetPage;

export {
  PasswordForgetForm,
  PasswordForgetLink,
};
