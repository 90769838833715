import React from 'react';
import './detailPics.css';
//import withAuthorization from '../../../Session/withAuthorization';



const detailLinks =(props)=>{
if(props.recentPicsLink !==""){
    return (

        <div id="detailPicBlocks">
            {props.cameras.map((item, index) =>{
                return(
                <div className="linkBox" id="camLink" href="">
                <a href={item}>
                   ACTIVE CAMERA INTERFACE <span className="redStyle">{props.letterArray[index]}</span>
                </a>
                <div></div> 
            </div>)
            })}

            <div className="linkBox" id="recentLink" href="">
            <a href={props.recentPicsLink}>
                RECENT PICS LINK
                </a>
                <div></div> 
           </div>
         
            
   
            <div className="linkBox" id="photoLink" href="">
                <a href={props.dropboxLink}>
                CONSTRUCTION PHOTOS & PLANS
                </a>
                <div></div>
            </div>
           

            <div className="dpc">
                <div className="detailPicsContainer1"> 
                    <img className ="dImgP" id="img1" src={props.pic1} alt=""/>
                </div>
                <div className="detailPicsContainer1"> 
                    <img className ="dImgP" id="img2" src={props.pic2} alt=""/>
                </div>
                <div className="detailPicsContainer1"> 
                    <img  className ="dImgP" id="img3" src={props.pic3} alt=""/>
                </div>
            </div>
        </div>
    )
} else  {


    return (

        <div id="detailPicBlocks">
            {props.cameras.map((item, index) =>{
                return(
                <div className="linkBox" id="camLink" href="">
                <a href={item}>
                   ACTIVE CAMERA INTERFACE <span className="redStyle">{props.letterArray[index]}</span>
                </a>
                <div></div> 
            </div>)
            })}

            <div className="linkBox" id="photoLink" href="">
                <a href={props.dropboxLink}>
                CONSTRUCTION PHOTOS & PLANS
                </a>
                <div></div> 
       

            </div>
        
            <div className="dpc">
                <div className="detailPicsContainer1"> 
                    <img className ="dImgP" id="img1" src={props.pic1} alt=""/>
                </div>
                <div className="detailPicsContainer1"> 
                    <img className ="dImgP" id="img2" src={props.pic2} alt=""/>
                </div>
                <div className="detailPicsContainer1"> 
                    <img  className ="dImgP" id="img3" src={props.pic3} alt=""/>
                </div>
            </div>
        </div>


    )

    }
}



export default detailLinks;