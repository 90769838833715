
 
import React from 'react';

import SiteCard from '../SiteCard/sitecard';
import InnerCard from '../InnerCard/innercard';
import firebase from '../../firebase/firebase';
import * as routes from '../../constants/routes';

import './siteBox.css';




const INITIAL_STATE = {
    allCities:[],
    allSites:{},
    sites:[],
    ssi:[]
}

class siteBox extends React.Component {
  constructor(props){
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    const db = firebase.firestore();
            db.settings({
                    // timestampsInSnapshots: true 
                    });
            var approvedID = [];
            var cityArray = [];
           
            db.collection('Users').where("uid","==",firebase.auth().currentUser.uid).get()
            .then(snapshot => {
              snapshot.forEach(doc => {
                if(doc.data().canSeeAll){
                  db.collection('SiteDetails').get()
            .then(snapshot => {
              snapshot.forEach(doc => {
                
                var city = doc.data().city;
               
                var i= cityArray.find(c=>c.name===city);
                if(i === undefined){
                  var c = new City(doc.data().city);
                  c.addSite(doc.data().id,doc.data().title);
                  cityArray.push(c);
                } else {
                  i.addSite(doc.data().id,doc.data().title);
                }
              })
              cityArray.sort(compare);
              this.setState({...INITIAL_STATE,allCities: cityArray});

            // console.log("ALength:"+approvedID.length);
            // console.log("CLength"+cityArray.length);
            // console.log("SLength:"+this.state.allCities);
            })
                } else{
                approvedID = doc.data().approvedIDs;
                
                approvedID.forEach(element =>{
              
                  db.collection('SiteDetails').where("id","==",element).get()
            .then(snapshot => {
              snapshot.forEach(doc => {
             
                var city = doc.data().city;
                var i= cityArray.find(c=>c.name===city);
                if(i === undefined){
                  var c = new City(doc.data().city);
                  c.addSite(doc.data().id,doc.data().title);
                  cityArray.push(c);
                } else {
                  i.addSite(doc.data().id,doc.data().title);
                }
              })
              cityArray.sort(compare);
              this.setState({...INITIAL_STATE,allCities: cityArray});

            // console.log("ALength:"+approvedID.length);
            // console.log("CLength"+cityArray.length);
            // console.log("SLength:"+this.state.allCities);
            })
                })}
              })
            })
              
            .catch(error => {
                    alert(error);
            });
  }//end of will mount


  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };


  goToCarddetails = (cardId) => {
    localStorage.setItem('selectedCard', cardId);
    this.props.history.push(routes.SITE_DETAILS);
// you can manage here to pass the clicked card id to the card details page if needed
}



  render() {

    return (
      <div id='siteHolder' className='center-align'> 
             {
               this.state.allCities.map(city=>{

                        if(city.name === undefined){
                          return (
                            <div></div>
                          )
                        }else{
                          return ( 
                              <a to={routes.SITE_DETAILS}><SiteCard className="pulse" img={require('../../assests/images/Cities/./'+city.name+'.jpg')}
                                city={city.name}
                                innercard = { 
                                  //<SiteList sites={doc.data().sites city={doc.id}/>
                                  <SiteList sites={city.sites} />
                                }                               
                              /></a> 
                          ) }   
                })                                     
              }     
      
      </div>
    )
  }//end of render 
}//end of class

//sorts cities array
function compare(a,b) {
  if(a.name<b.name) return -1;
  if(a.name>b.name) return 1;
  return 0;
}

//populates innercard sites
function SiteList(props){
  const sites = props.sites;
  // console.log(Object.keys(sites[0])[0]);
  // console.log(sites[0].values().next().value);

  const listItems = sites.map((site) =>

  <li className="liSite" key={Object.keys(site)[0]}><InnerCard siteP={site} mg={require('../../assests/images/jw.jpg')}/></li>
  );
  // console.log(listItems);
  return (
    <div>
      <ul>{listItems}</ul>
    </div>
  )
}

class City{

  constructor(name){
    this.name = name;
    this.sites = [];
  }

  addSite(siteD,siteName){
    var m = new Map();
    m.set(siteD,siteName);
    this.sites.push(m);
  }

  getName(){
    return this.name;
  }

  getSites(){
    return this.sites;
  }
}

export default siteBox;