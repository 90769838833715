import React, { Component } from 'react';

import AuthUserContext from '../Session/AuthUserContext';
//import NavigationAuth from './navigationAuth';
import NavigationNonAuth from './NavigationNonAuth';
import NavigationAdmin from './navigationAdmin';
import './navigation.css';

const INITIAL_STATE = {
value:""

}




class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }




render(){


  
    return (
//test6
        <AuthUserContext.Consumer>
           {
           
             /* authUser => authUser
       ? <NavigationAuth/>:<NavigationNonAuth /> */

       authUser => authUser
       ? <NavigationAdmin/>:<NavigationNonAuth />

      
     }


   
        </AuthUserContext.Consumer>  
  )
}
}


export default Navigation;
