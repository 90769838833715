import React from 'react';
import './header.css';


const header =(props)=>{
    return (
        <div id="nonAuth">

       
                    <img  id="whiteLogoh" src ={require('../../assests/images/logos/wlLogoWhite.png')} alt="White Lodging Logo"/>    
            

            <div id="naText">
                <span id='topTitle'>WHITE LODGING</span><br />
                <span id="iS">INVESTMENT & DEVELOPMENT</span>
            </div>  
        </div>
    )
}

export default header; 

