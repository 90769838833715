import React from 'react';
import './grayFooter.css';



const grayFooter = ()=>{


return (


    <div id="grayFooter">
        <div className="gfDivs" >
            <span className="boldStyle2GF">Important Notice: </span><span className='grayStyle'>
            This website is only to be accessed by authorized users.  Official User Name and Passwords are provided to authorized users.
            If you do not have an authorization you are forbidden access, use and all privelages of the information and images on this website.
            Any unauthorized use of this website is strictly prohibited and will be enforced.</span>
        </div>


        <div className="gfDivs"><span className='lightGrayStyle'>
            Over the last 30 years, White Loding has outpaced the hotel development industry with 120 newly constructed hotels, an investment more than $3 billion in the making.  On average, White Lodging has $500 million - $1 billion in active development.  Our development team excels at finding the right markets for new builds and delivering high-value projects both within budget and on schedule.
        </span></div>

    </div>

)

}

export default grayFooter;
