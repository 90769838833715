//This component structures the List Page for for the Admins Sites

import React, { Component } from 'react';
import withAuthorization from '../Session/withAuthorization';
import './manageUsers.css';
import firebase from '../../firebase/firebase';
//import CheckBox from './adminComponents/adminCheckBoxOld';
import Header from '../Header/header';
import Title from '../Title/title';
//import M from "materialize-css";
import Select from 'react-select';




const INITIAL_STATE = {
  sitename: '',
  allUsers: [],
  allSites: [],
  email: "",
  firstname: "",
  lastname: "",
  group: [],
  error: null,
  show: false,
  showA: false,
  siteIDArray: [],
  associatedSiteArray: [],
  siteIsResolving: true,
  usersAreResolving: true,
  checkboxes: [],
  canSeeAll: false
};

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? 'red' : 'black',
      color: '#FFF',
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
};

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});


// function compare(userArray, siteArray) {

//   const finalarray = [];

//   userArray.forEach((e1) => siteArray.forEach((e2) => {
//     if (e1 === e2) {
//       finalarray.push(userArray)
//     }
//   }
//   ));
//   return finalarray;
// };
//test


class manageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  toggleUser() {
    this.setState({
      showA: !this.state.showA
    })
  }

  onSubmit = (e) => {
   
    let tempIndex=-1;
    for(var i =0;i<this.state.allUsers.length;i++){
      if(this.state.allUsers[i].email===this.state.email){
        tempIndex=i;
        break;
      }
    }

    if(tempIndex!==-1){
      let tempCheckbox=this.state.checkboxes;
      let tempArray = [];
      let tempAllUsers = this.state.allUsers;
      let tempSeeAll = this.state.canSeeAll;

      tempCheckbox.forEach(checkbox=>{
        if(checkbox.checked){
          tempArray.push(checkbox.siteID);
        }
      })
      const db = firebase.firestore();
            db.settings({
                    // timestampsInSnapshots: true 
                    });
      db.collection('Users').where("email","==",this.state.email).get().then(snapshot=>{
        snapshot.forEach(doc=>{
          var o={};
          o.approvedIDs=tempArray;
          o.canSeeAll = tempSeeAll;
          o.firstname = this.state.firstname;
          o.lastname = this.state.lastname;
          db.collection('Users').doc(doc.id).update(o);
          })
        })
      tempAllUsers[tempIndex].approvedIDs=tempArray;
      tempAllUsers[tempIndex].canSeeAll=tempSeeAll;
      tempAllUsers[tempIndex].firstname=this.state.firstname;
      tempAllUsers[tempIndex].lastname = this.state.lastname;
   
      this.setState({
        allUsers : tempAllUsers
      });
      e.preventDefault();
      window.alert("User updated")
    }
    }
    



  componentDidMount() {
    const db = firebase.firestore();
    db.settings({
      // timestampsInSnapshots: true
    });

    var tempUsers = [];

    db.collection('Users').get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          tempUsers.push(doc.data())
    
        });
     
        this.setState({ usersAreResolving: false, allUsers: tempUsers });
      }
      )

      .catch(error => {
        alert(error);
      })

    
    var tempSites = [];
    var tempSiteIDArray = [];
    var tempcheckboxes = [];
    db.collection('SiteDetails').get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          tempSites.push(doc.data());
          tempSiteIDArray.push(doc.data().id);
          tempcheckboxes.push({ label: doc.data().title, siteID: doc.data().id, checked: false });
          
     
        })
      
        this.setState({ siteIsResolving: false, allSites: tempSites, siteIDArray: tempSiteIDArray, checkboxes: tempcheckboxes });

      })



    window.scrollTo(0, 0)
    //grab users









  }//end of will mount

  toggleBox(){

    let temCanSeeAll = this.state.canSeeAll;
    let temAllUsers = this.state.allUsers;
    temCanSeeAll = !temCanSeeAll;
    if(temCanSeeAll){
      let tempIndex=-1;
      for(var i =0;i<this.state.allUsers.length;i++){
        if(this.state.allUsers[i].email===this.state.email){
          tempIndex=i;
          break;
        }
      }
  
      if(tempIndex!==-1){
        temAllUsers[tempIndex].canSeeAll=temCanSeeAll;
      }
      this.updateCheckboxes(this.state.email);
    }
  
    this.setState({
      allUsers: temAllUsers,
      canSeeAll: temCanSeeAll
    })
  }

  toggleCheckbox(index) {
    let tempCheckbox = this.state.checkboxes;

    tempCheckbox[index].checked = !tempCheckbox[index].checked;

    this.setState({
      checkboxes: tempCheckbox
    });
  }

  updateCheckboxes(email) {
   
    var result = this.state.allUsers.find(x => x.email === email)
    let tempCheckbox = this.state.checkboxes;
    if (typeof result !== 'undefined'){
      tempCheckbox.forEach(checkbox => {
        if (result.canSeeAll) {
          checkbox.checked = true;
        } else {
          if (result.approvedIDs.indexOf(checkbox.siteID) !== -1) {
            checkbox.checked = true;
          } else {
            checkbox.checked = false;
          }
        }
      })
      this.setState({
        checkboxes: tempCheckbox,
        canSeeAll: result.canSeeAll,
        lastname: result.lastname,
        firstname: result.firstname
      });
    } else{
      tempCheckbox.forEach(checkbox => {
        checkbox.checked = false;
      })
      this.setState({
        checkboxes: tempCheckbox,
        canSeeAll: false,
        lastname: "",
        firstname: ""
      });
    }
  }

  handleDelete(){
    if(window.confirm('Are you sure you want to delete this user. If you do, remember to go into Firegase>Authentication>find the user and delete there as well')){
      this.deleteUser();
    }
  }

  handlePasswordReset(){
    var auth = firebase.auth();
    auth.sendPasswordResetEmail(this.state.email).then(function() {
    alert("Email sent")
    }).catch(function(error) {
    // An error happened.
    });
  }

  deleteUser() {
    const db = firebase.firestore();
            db.settings({
                    // timestampsInSnapshots: true 
                    });
    db.collection('Users').where("email","==",this.state.email).get().then(snapshot=>{
      snapshot.forEach(doc=>{
        db.collection('Users').doc(doc.id).delete();
        }) 
      })
      let tempIndex=-1;
      for(var i =0;i<this.state.allUsers.length;i++){
        if(this.state.allUsers[i].email===this.state.email){
          tempIndex=i;
          break;
        }
      }
  
      if(tempIndex!==-1){
        let tempAllUsers = this.state.allUsers;
        tempAllUsers.splice(tempIndex,1);
        this.setState({
        allUsers : tempAllUsers,
        firstname: "",
        lastname: "",
        email: ""
        });
        this.updateCheckboxes();
      }
  }




  render() {
    if (this.state.siteIsResolving || this.state.usersAreResolving) { return null } else {




      //get the selected user's associated ids
      //use the compare function to passing in array of user's id and siteIDarray from state
      //set the state of the final array
      //loop through and evaluate final array with site id

      return (


        <div id='adminContainer'>
    


          <div className="container">
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <Select options={this.state.allUsers.map((user) => {
                  return ({ label: user.email, value: user.uid })
                })} onChange={(opt, meta) => {
                  this.setState({ email: opt.label })
                  this.updateCheckboxes(opt.label);
                  return true;
                }
                } placeholder="Select User" styles={colourStyles} id="selectContainer"/>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>



          <div id="editContainer" className='container white'>
            <Header style="wlLogoNonAlt" color="white-text" />
            <div class="row">
              <div class="col2 s12 m6">
                <div class="card light-grey darken-1 z-depth-4">
                  <div class="card-content">
                    <Title color="titleW" title="MANAGE USER" />



                    <form onSubmit={this.onSubmit}>
                      
                      <label className="labelDiv">
                        <span className="labelText">First Name</span>
                        <input
                        class="input-field white-text"
                        value={this.state.firstname}
                        onChange={event => this.setState(updateByPropertyName('firstname', event.target.value))}
                        type="text"
                        placeholder="ex. John"
                        /> 
                      </label>

                      <div>
                        <label className="labelDiv">
                          <span className="labelText">Last Name</span>
                          <input
                          className="input-field white-text"
                          value={this.state.lastname}
                          onChange={event => this.setState(updateByPropertyName('lastname', event.target.value))}
                          type="text"
                          placeholder="ex. Deere"
                          />
                        </label>
                      </div>
                      <div>
                        <label className="labelDiv">
                          <span className="labelText">Email Address</span>
                        </label>
                      </div>
                      <input
                        className="input-field white-text gray lighten-2 "
                        value={this.state.email}
                        onChange={event => this.setState(updateByPropertyName('email', event.target.value))}
                        type="text"
                        placeholder="ex. john@deere.com"
                      />
                      <div id='adminFormSub'>Associated Sites</div>
                      <div>
                        <label key="checkAllBox">
                          <input type='checkbox' checked={this.state.canSeeAll} name="canSeeAll" key="checkAllBox" value="checkAllBox" onChange={this.toggleBox.bind(this)}/>
                          <span>Can See All</span>
                        </label>
                      </div>
                      

                      {this.state.checkboxes.map((checkbox, index) => {

                        return (
                          <label key={checkbox.siteID}>
                            <input type='checkbox' checked={this.state.checkboxes[index].checked} name={checkbox.label} key={checkbox.siteID} value={checkbox.siteID} onChange={this.toggleCheckbox.bind(this, index)} />
                            <span>{checkbox.label}&emsp;</span>

                          </label>
                        );

                      })}

                    <div style={{display : 'flex', justifyContent : 'space-around'}}>
                      <button className ='btn pink lighten-1 z-depth-0' type="submit">
                     Update</button>
                     <button  className = 'btn pink lighten-1 z-depth-0' type="button" onClick={this.handlePasswordReset.bind(this)}>
                      Reset Password</button>
                     <button  className = 'btn pink lighten-1 z-depth-0' type="button" onClick={this.handleDelete.bind(this)}>
                      Delete</button>
                     </div>


                    




                    
                    


                      {this.state.error && <p>{this.state.error.message}</p>}

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>


      )
    }


  }
}




const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(manageUsers);


