
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const PASSWORD_FORGET = '/pw-forget';
export const HOME = '/';
export const ACCOUNT = '/account';
export const MANAGE_USERS ='/manageusers';
export const MANAGE_SITES ='/managesites';
export const CREATE_SITE = '/admin/createsite';
export const USER_PAGE = '/admin/users';
export const SITE_DETAILS = '/site-details/:siteid';
export const COMING_SOON = '/coming-soon';

