import React from 'react';
import './comingSoon.css';
import withAuthorization from '../Session/withAuthorization';


const comingSoon =(props)=>{
    return (
        <div id="csoonContainer">

                    <div id="csText">COMING SOON</div>  
                    <img  id="csBackgroundImg" alt="" src ={require('../../assests/images/csBg.png')}/>    
                   
          
        </div>
    )
}


const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(comingSoon); 

