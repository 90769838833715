import React from 'react';
import './streamBox.css';





const streamBox = (props) => {


    //let selectedCardId = localStorage.getItem("selectedCard");

let display = "image"

if(display === "image"){
    return (
        <div className ="mediaDivImg">
             <img id="imageFeed" src={props.imageURL} alt="Latest camera feed"/>
            
            <p>MOST RECENT PICTURE [EVERY 10-15 MINUTES]</p>
        </div>
    )

} else  {
return (

    <div className ="mediaDiv">
        <video id="videofeed" autoplay controls>
            <source src={props.videoURL}  type="video/mp4" autoplay="true" loop='true'  />
        </video>
        <p>MOST RECENT PICTURE [EVERY 10-15 MINUTES]</p>
    </div>

 
)

}
}
    export default streamBox;