import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import jquery from 'jquery';
window.$ = window.jQuery=jquery;

// import { createStore, applyMiddleware } from 'redux';
// import RootReducer from './store/reducers/RootReducer';
// import { Provider } from 'react-redux';
// import Thunk from 'redux-thunk';

// const store = createStore(RootReducer, applyMiddleware(Thunk));

ReactDOM.render(<App />,document.getElementById('root')

);

// ReactDOM.render(<Provider store={store}><App /></Provider>,document.getElementById('root'));
// registerServiceWorker();



