import React from 'react';


const divider =(props)=>{

    return (
    <div className={props.divDivider} id={props.id}><span className={props.titleColor}>{props.siteTitle}</span></div>
    )}
    
    
    export default divider;