import React from 'react';
import './bottomHeader.css';
import { Link } from 'react-router-dom';
import * as routes from '../../../constants/routes';


const bottomHeader = () =>{

    return (
        <div id="bottomheaderContainer">
        
            <div id="bottomQuote">SUCCESS KNOWS NO BOUNDARIES</div>

            <div className="btnsContainer">
            <Link to={routes.HOME}><div className="whiteBtn">MAIN MENU</div></Link>
                <a href="#siteDetailsContainer"><div className="whiteBtn">GO TO TOP</div></a>
            </div>
        </div>
    )

}

export default bottomHeader;