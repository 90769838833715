import React from 'react';
import {
  withRouter,
} from 'react-router-dom';

import './sitecard.css';
const siteCard =(props)=>{

return (
  <div id="card" className="card hoverable">

    <div className="card-image waves-effect waves-block waves-light" >
      <img className="activator" src={props.img} alt="" />
      <span id="card-title" className="card-title">
        <p id="ccName">{props.city}</p>
      </span>
    </div>
    

    <div className="card-reveal black">
      <span className="card-title"><i id="close" className="material-icons right">close</i></span>
      <ul>
        <li className="black">{props.innercard}</li>
        {/* <li><div id="contentLine"></div></li> */}
      </ul>
    </div>
  </div>
  )
}

export default withRouter(siteCard);