import React from 'react';
import './innercard.css';
import {
  Link,
  withRouter,
} from 'react-router-dom';

const innerCard = (props) =>{
  //<Link id="urllink" to={{pathname:'/site-details/'+props.cityName+'/'+encodedSiteName, state:{ foo: 'bar'}}}>

return (
  
    <div id="innerContainer">
      <div id="innerh" className="card horizontal">
      <div className="card-content">
          <Link id="urllink" to={{pathname:'/site-details/'+props.siteP.keys().next().value, state:{ foo: 'bar'}}}> 
            <p className="innerSub">{props.siteP.values().next().value}</p> 
          </Link>
        </div>
      </div>
    <div className="card-stacked">
    </div> 
  </div>
    )   
}


export default withRouter(innerCard);