//This component structures the List Page for for the Admins Sites

import React, { Component } from 'react';
import withAuthorization from '../Session/withAuthorization';
import './siteDetails.css';
import firebase from '../../firebase/firebase';
import StreamBox from '../SiteDetails/Details/StreamBox/streamBox';
import Divider from '../Divider/divider';
import DetailsBox from '../SiteDetails/Details/DetailsBox/detailsBox';
import ProjectTeam from '../SiteDetails/Details/ProjectTeam/projectTeam';
import DetailLinks from '../SiteDetails/Details/DetailPics/detailLinks';
import BottomHeader from '../Footer/BottomHeader/bottomHeader';


const INITIAL_STATE = {
  sitename:"",
  recentPicsLink:"",
  cityname:'',
  wholeData:[],
  allDetails:{},
  Title:"",
  h1:"",
  s1:"",
  h2:"",
  s2:"",
  h3:"",
  s3:"",
  boldText:"",
  redStyle:"",
  projectDesc:"",
  camera1:"",
  cameras:[],
  dropboxLink:"",
  pic1:"",
  pic2:"",
  pic3:"",
  latestPic:"",
  teamMembers:[],
  letterArray : ['A','B','C','D','E','F'],
  error:null,
  show:false
};





class SiteDetails extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {...INITIAL_STATE};


    let url = window.location.href;
    console.log(url);
    console.log(decodeURI(url));
    var urlArray = decodeURI(url).split("/");
    var sitID = urlArray[4];
    console.log(urlArray);

    this.state.siteID = sitID;
  }



  componentDidMount(){

    window.scrollTo(0, 0)

    const db = firebase.firestore();

    db.settings({
            // timestampsInSnapshots: true 
            });                            
              
              db.collection('Users').where("uid","==",firebase.auth().currentUser.uid).get()
            .then(snapshot => {
              snapshot.forEach(doc => {
                var approvedIDs = doc.data().approvedIDs;
                if(doc.data().canSeeAll||approvedIDs.includes(this.state.siteID)){
                  console.log("LOOK");
                  console.log("Showing");
                  var docRef = db.collection('SiteDetails').where("id","==",this.state.siteID);
  
                docRef.get()
                .then(snapshot =>{
                  console.log("Snap size:"+snapshot.size);
                  snapshot.forEach(doc => {
                  console.log(doc.data());
                  console.log(doc.data().title);
                  console.log(doc.data().h1);
                  console.log("Doc Team:"+doc.data().teamMembers);
                  this.setState({Title: doc.data().title});
                  this.setState({h1: doc.data().h1});
                  this.setState({s1: doc.data().s1});
                  this.setState({h2: doc.data().h2});
                  this.setState({s2: doc.data().s2});
                  this.setState({h3: doc.data().h3});
                  this.setState({s3: doc.data().s3});
                  this.setState({boldText: doc.data().boldText});
                  this.setState({redStyle: doc.data().redStyle});
                  this.setState({projectDesc: doc.data().projectDesc});
                  this.setState({camera1: doc.data().camera1});
                  this.setState({cameras: doc.data().cameras});
                  this.setState({dropboxLink: doc.data().dropboxLink});
                  this.setState({pic1: doc.data().pic1});
                  this.setState({pic2: doc.data().pic2});
                  this.setState({pic3: doc.data().pic3});
                  this.setState({latestPic: doc.data().latestPic});
                  this.setState({teamMembers: doc.data().teamMembers});
                  this.setState({recentPicsLink:doc.data().recentPicsLink});
                  })
                  console.log(this.state.recentPicsLink);
                }
                )
                  this.setState({
                    show:true
                  })
                }
              }
              )});
              


   
}//end of will mount




    render(){


      if(!this.state.show){
        return(
        //put COMING SOON code here
        null
          );
      }


        
        return(




          
          <div id="siteDetailsContainer">
                    <div>
                    <div id="siteDetailsContent">



                  
                      <div id='aBox'>
                        <Divider siteTitle={this.state.Title} titleColor="blackTitleDetails" id="dividerD" />
                        <StreamBox videoURL="" imageURL={this.state.latestPic} />
                      </div>
                      <div id="bBox">
                        <DetailsBox h1={this.state.h1} s1={this.state.s1} h2={this.state.h2} s2={this.state.s2} h3={this.state.h3} s3={this.state.s3} boldText={this.state.boldText} redStyle={this.state.redStyle} projectDesc={this.state.projectDesc} style="newCityDesc" />
                      </div>
                      <div id="cBox">  
                          <DetailLinks  cameras={this.state.cameras} letterArray = {this.state.letterArray} dropboxLink={this.state.dropboxLink} recentPicsLink = {this.state.recentPicsLink} pic1={this.state.pic1} pic2={this.state.pic2} pic3={this.state.pic3} />                 
                        {/* <DetailLinks cameras ={this.state.cameras} letterArray={this.state.letterArray} camera1={this.state.camera1} dropboxLink={this.state.dropboxLink}  /> */}
                        {/* <DetailPics pic1={this.state.pic1} pic2={this.state.pic2} pic3={this.state.pic3}/> */}
                      </div> 
                      <div id="dBox" className="deatailPics">
                        <ProjectTeam teamMembers={this.state.teamMembers} />
                      </div> 
                    </div>

                    <div id="detailsBottme ">
                        <BottomHeader />
                    </div>
                    </div>
                  )//end of inner return
                )
            </div>//container 
          )
        }


  }



const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(SiteDetails);




