import React, { Component } from 'react';
import withAuthorization from '../Session/withAuthorization';
import SiteBox from '../../components/SiteBox/siteBox';
import Divider from '../Divider/divider';
import '../Divider/divider.css';
import './home.css';



const INITIAL_STATE = {
  email:'',
  usergroup:[],
  userid:'',
  allGroups:[],
  error: null,

};

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }


//gets user info to pass as props






  render() {
   
 
    return (

      <div id = "home-container">

      <Divider siteTitle="INVESTMENTS & DEVELOPMENT" titleColor="blackTitle" id="divider" />
      <div id="cContainer">
        <SiteBox />
      </div>
    </div>
 
    );
  }
}


  
const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(HomePage);