import React from 'react';
import {
  BrowserRouter as Router,
  Route,Switch
} from 'react-router-dom';

import Navigation from '../Navigation/navigation';
import ManageUsersPage from '../ManageUsers';
import ManageSitesPage from '../ManageSites';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import withAuthentication from '../Session/withAuthentication';
import * as routes from '../../constants/routes';
import './index.css';
//import this in the builder later 
import SiteDetailsPage from '../SiteDetails';
import CssBaseline from '@material-ui/core/CssBaseline';
import Footer from '../Footer/footer';
import ComingSoonPage from '../ComingSoon/comingSoon';  




const App = () =>{

return (

  <Router>
  

    <div className='app' >
  
    <Navigation />
       <CssBaseline />
     
<Switch>
      <Route exact path={routes.SIGN_UP} component={() => <SignUpPage />} />
      <Route exact path={routes.SIGN_IN} component={() => <SignInPage />} />
      <Route exact path={routes.PASSWORD_FORGET} component={() => <PasswordForgetPage />} />
     <Route exact path={routes.HOME} component={(props) => <HomePage {...props}/>} />
      <Route exact path={routes.ACCOUNT} component={() => <AccountPage />} />
      <Route exact path={routes.MANAGE_USERS} component={()=> <ManageUsersPage />} />
      <Route exact path={routes.MANAGE_SITES} component={()=> <ManageSitesPage />} />
      {/* <Route exact path={rofirutes.SITE_DETAILS} render={(props)=><SiteDetailsPage {...props}/>} /> */}
      <Route exact path={routes.SITE_DETAILS} component={()=><SiteDetailsPage/>} />
      <Route exact path={routes.COMING_SOON} component={()=> <ComingSoonPage />} />
</Switch>

<Footer />
    </div>


  </Router>

)}

export default withAuthentication(App);