import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';

const prodConfig = {
  apiKey: "AIzaSyAkSA6WYxmtrXkNc0jod8hYMraM0YJoDeQ",
      authDomain: "wltour-e8c01.firebaseapp.com",
      databaseURL: "https://wltour-e8c01.firebaseio.com",
      projectId: "wltour-e8c01",
      storageBucket: "wltour-e8c01.appspot.com",
      messagingSenderId: "930471323735"
};

const devConfig = {
  apiKey: "AIzaSyAkSA6WYxmtrXkNc0jod8hYMraM0YJoDeQ",
      authDomain: "wltour-e8c01.firebaseapp.com",
      databaseURL: "https://wltour-e8c01.firebaseio.com",
      projectId: "wltour-e8c01",
      storageBucket: "wltour-e8c01.appspot.com",
      messagingSenderId: "930471323735"
};

const config = process.env.NODE_ENV === 'production'
  ? prodConfig
  : devConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const db = firebase.database();
const auth = firebase.auth();
const fs = firebase.firestore()

export {

  db,
  auth,
  fs
};

export default firebase;
