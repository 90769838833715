import React, { Component } from 'react';
import {
  Link,
  withRouter,
} from 'react-router-dom';
import Header from '../Header/header';
import Title from '../Title/title';
import { auth, fs } from '../../firebase';
import * as routes from '../../constants/routes';
const SignUpPage = ({ history }) =>
  <div>
   
    <SignUpForm history={history} />
  </div>

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  passwordOne: '',
  passwordTwo: '',
  firstname:'',
  lastname:'',
  error: null,
};

class SignUpForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const {
      email,
      passwordOne,
      firstname,
      lastname
    } = this.state;

    auth.doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {

        // Create a user in your own accessible Firebase Database too
        fs.createUser(authUser.user.uid,firstname,lastname, email, null)
          .then(() => {
            this.setState(() => ({ ...INITIAL_STATE }));
            
          })
          .catch(error => {
            this.setState(updateByPropertyName('error', error));
          });

      })
      .catch(error => {
        this.setState(updateByPropertyName('error', error));
      })
      .finally(()=> {
        this.props.history.push(routes.HOME)
      }
        
      )

    event.preventDefault();
    
  }

  render() {
    const {
      firstname,
      lastname,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      firstname === '' ||
      lastname === '' ||
      email === '';

    return (
    
      
      <div id="container" className='container white'>
      <Header style="wlLogoNonAlt" color="white-text"/>
        <div class="row">
          <div class="col2 s12 m6">
            <div class="card light-grey darken-1 z-depth-4">
              <div class="card-content">
              <Title color="titleW" title="Sign Up" />
      <form onSubmit={this.onSubmit}>
      
                      <input
                      class="input-field white-text"
                      value={firstname}
                      onChange={event => this.setState(updateByPropertyName('firstname', event.target.value))}
                      type="text"
                      placeholder="First Name"
                    />
                      <input
                      className="input-field white-text"
                      value={lastname}
                      onChange={event => this.setState(updateByPropertyName('lastname', event.target.value))}
                      type="text"
                      placeholder="Last Name"
                    />
                    <input
                    className="input-field white-text gray lighten-2 "
                      value={email}
                      onChange={event => this.setState(updateByPropertyName('email', event.target.value))}
                      type="text"
                      placeholder="Email"
                    />
                    <input  id="eBox"
                    className="input-field white-text strong"
                      value={passwordOne}
                      onChange={event => this.setState(updateByPropertyName('passwordOne', event.target.value))}
                      type="password"
                      placeholder="Password"
                    />
                    <input
                    className="input-field white-text"
                      value={passwordTwo}
                      onChange={event => this.setState(updateByPropertyName('passwordTwo', event.target.value))}
                      type="password"
                      placeholder="Confirm Password"
                    />
                    
                    <button className ='btn pink lighten-1 z-depth-0' disabled={isInvalid} type="submit">
                      Sign Up</button>
                    

                    { error && <p>{error.message}</p> }
        
                </form>
              </div>
            </div>
          </div>
        </div>   
      </div>
    );
  }
}

const SignUpLink = () =>
  <p className='white-text'>
    Don't have an account?
    {' '}
    <Link to={routes.SIGN_UP}><span className="bold blue-text">Sign Up</span></Link>
  </p>

export default withRouter(SignUpPage);

export {
  SignUpForm,
  SignUpLink,
};