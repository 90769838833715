import React from 'react';
import './detailsBox.css';
//import {Modal,Button} from 'react-bootstrap';




const detailsBox =(props)=>{
    


    return (

        <div id="detailsContainer" >
      
            <div id = "detailsHeader">
                
                <div className="dSub">
                        <span className="sub">{props.h1}</span> <br /> 
                        <span className="sub2">{props.s1}</span></div>
                <div className="dSub">
                    <span className="sub">{props.h2}</span> <br />
                    <span className="sub2">{props.s2}</span></div>
                {/* <div id="lastdSub" className="dSub">
                    <span className="sub">{props.h3}</span> <br />
                    <span className="sub2">{props.s3}</span></div>*/}
            </div> 
         
        <p><span id="po">PROJECT OVERVIEW</span></p>
          <p className="cityDesc"><span className="boldText">{props.boldText}</span><span className="redStyle">{props.redStyle}</span>
          {props.projectDesc}

          
          </p>
          {/* <button id="rMore" onClick={props.rClick} >Read More...</button> */}


          {/* <div className="modal-container">

<Modal className="z-index-0" show={props.show} onHide={props.onHide}>
  <Modal.Header >
    <Modal.Title>Sites</Modal.Title>
  </Modal.Header>
  <Modal.Body className="black-text">
  <ul class="collection with-header align-center">
        <li class="collection-header"><h4>First Names</h4></li>
        <li class="collection-item"><div>Alvin<a href="#!" class="secondary-content"><i class="material-icons">send</i></a></div></li>
        <li class="collection-item"><div>Alvin<a href="#!" class="secondary-content"><i class="material-icons">send</i></a></div></li>
        <li class="collection-item"><div>Alvin<a href="#!" class="secondary-content"><i class="material-icons">send</i></a></div></li>
        <li class="collection-item"><div>Alvin<a href="#!" class="secondary-content"><i class="material-icons">send</i></a></div></li>
      </ul>
  </Modal.Body>
  <Modal.Footer>
    <Button onClick={props.closeModal}>Close</Button>
  </Modal.Footer>
</Modal>
</div> */}


           
      </div>   
     
    )}
    
export default detailsBox;