import React from 'react';

import AuthUserContext from '../Session/AuthUserContext';
import PasswordChangeForm from '../PasswordChange';
import withAuthorization from '../Session/withAuthorization';

const AccountPage = () =>
  <AuthUserContext.Consumer>
    {authUser =>
      <div>
        <h1 className='white-text'>ACCOUNT: <span className='green-text darken-3'>{authUser.email}</span></h1>
       
        <PasswordChangeForm />
      </div>
    }
  </AuthUserContext.Consumer>

const authCondition = (authUser) => !!authUser;


//in case we want this form 
//<PasswordForgetForm />
export default withAuthorization(authCondition)(AccountPage);