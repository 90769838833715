import React from 'react';
import './title.css';



const title =(props)=>{
    return (
    <span id="titleContainer" className={props.color}><h4>{props.title}</h4></span>
)}

export default title;