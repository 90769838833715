import { fs } from './firebase';
import { firestore } from 'firebase';


//Users --------------
export const createUser = (uid, firstname, lastname, email) =>{
    fs.settings({
        // timestampsInSnapshots: true
    });
    
    fs.collection("Users").add({
        uid:uid,
        email: email,
        firstname: firstname,
        lastname: lastname,
        approvedIDs: [],
        canSeeAll: false
    });   
}

export const getUserInfoFromEmail = (userEmail) =>{

    fs.collection("Users")
    .where("email", "==", userEmail)

}

//sites ------------------------
export const createSite = (statename,city) => {
    fs.settings({
        // timestampsInSnapshots: true
    });
    // city, sitename, cam1, description
    fs.collection("Test").add({
        statename:statename,
        city:city
        // sitename:sitename,
        // cam1:cam1,
        // description:description
    });   
}


export const getAllSitesFromGroup = () => {
    fs.collection("Groups")
    .orderBy("sites", "asc")
    
    }
    
export const getASiteFromGroup = (groupname) =>{
    fs.collection("Groups")
.where("sites", "==", groupname)
}

// groups---------------------------
export const createGroupwithUserandSite = (groupname,sitename,username)=>{
    fs.settings({
        // timestampsInSnapshots: true
    });
    //check if group exist
    var groupRef = fs.collection("Groups").doc(groupname);
        groupRef.set(    
    
    {
           sites:firestore.FieldValue.arrayUnion(sitename),
           users:firestore.FieldValue.arrayUnion(username)     
        }
    )
}

export const addUserToGroup = (username,groupname)=>{
    fs.settings({
        // timestampsInSnapshots: true
    });
    //check if group exist
    fs.collection("Groups").doc(groupname).update(
        {
           users:firestore.FieldValue.arrayUnion(username)     
        }
    )
}

export const addSiteToGroup = (sitename,groupname)=>{
    fs.settings({
        // timestampsInSnapshots: true
    });
    //check if group exist
    fs.collection("Groups").doc(groupname).update(
        {
           sites:firestore.FieldValue.arrayUnion(sitename)     
        }
    )
}


// export const getAGroup = (file) => {
// fs.collection("Groups").doc(groupname).get();
// }


// export const addPic = (file) => {
//     var storage = fs.app().storage();
//     var storageRef = storage.ref();
//     var spaceRef = storageRef.child(file.name)

//     ref.put(file).then(function(snapshot) {
//         console.log("File uploaded");
//         alert("File uploaded");

//     });

// }
