//This component structures the List Page for for the Admins Sites

import React, { Component } from 'react';
import withAuthorization from '../Session/withAuthorization';
import './manageSites.css';
import firebase from '../../firebase/firebase';
import Header from '../Header/header';
import Title from '../Title/title';
import M from "materialize-css";
import Select from 'react-select';
import FileUploader from "react-firebase-file-uploader";
import { storage } from 'firebase';
import { CREATE_SITE } from '../../constants/routes';






const collection = 'SiteDetails';
const INITIAL_STATE = {

  image1:null,
  imageURL1:"",
  image2:null,
  imageURL2:"",
  image3:null,
  imageURL3:"",

    sitename:'',
    recentPicsLink:'',
    city:'',
    wholeData:[],
    allSites:[],
    allDetails:{},
    mainCamera:'',
    secondaryCamera:'',
    title:"",
    h1:"",
    s1:"",
    h2:"",
    s2:"",
    h3:"",
    s3:"",
    boldText:"",
    redStyle:"",
    projectDesc:"",
    cameras:[],
    dropboxLink:"",
    pic1:"",
    pic2:"",
    pic3:"",
    latestPic:"",
    teamMembers:[],
    teamMemberOne:'',
    teamMemberTwo:'',
    teamMemberThree:'',
    teamMemberFour:'',
    error:null,
    largestID:0,
    currentID:9999,
  show: false,
  showA: false,
  siteIDArray: [],
  associatedSiteArray: [],
  sitesAreResolving: true,
  updateText: 'Update'
};

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? 'red' : 'black',
      color: '#FFF',
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
};

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});
//class ----------------------
class manageSites extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {...INITIAL_STATE };
    this.setRef = ref =>{
      this.file = ref;
    }
    const db = firebase.firestore();
    db.settings({
      // timestampsInSnapshots: true
    });

    var tempSites = [];
    var create = {};
    create.title="CREATE NEW SITE";
    create.city="";
    create.cameras=[];
    create.teamMembers=[];
    create.h1="";
    create.h2="";
    create.h3="";
    create.s1="";
    create.s2="";
    create.s3="";
    create.boldText="";
    create.redStyle="";
    create.recentPicsLink="";
    create.dropboxLink="";
    create.projectDesc="";
    create.latestPic="";
    db.collection(collection).get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          tempSites.push(doc.data());
          //this.state.allSites.push(doc.data());
          console.log(doc.data().title+ " - " +doc.data().id);
          if(Number(doc.data().id)>this.state.largestID){
            this.setState({largestID: doc.data().id})
          }
        });
        //console.log("Through sites");
        create.id=Number(this.state.largestID)+1;
        tempSites.push(create);
        tempSites.sort(compare);
        this.setState({ sitesAreResolving: false, allSites: tempSites, largestID: create.id});
        console.log(this.state.largestID);
      }
      )
      .catch(error => {
        alert(error);
      })
  }



  componentDidMount() {
    window.scrollTo(0, 0);  
  }//end of will mount



  onImageChange1 = (e) => {
 
  const file = this.fileUpload1.files[0];

  this.setState({image1:file})

  if(file){
  
    const storageRef = firebase.storage().ref();
    const mainImage = storageRef.child(file.name);
       mainImage.put(file).then((snapshot) => {
        mainImage.getDownloadURL().then((url) =>{
        const image = document.getElementById("target1");
         this.setState({imageURL1:url});
         image.src = url;
        })
      })
    }
  }

  onImageChange2 = (e) => {
    const file = this.fileUpload2.files[0];
    this.setState({image2:file})
    if(file){
      const storageRef = firebase.storage().ref();
      const mainImage = storageRef.child(file.name);
         mainImage.put(file).then((snapshot) => {
          // console.log("test + " + file )
          mainImage.getDownloadURL().then((url) =>{
            
           this.setState({imageURL2:url});
           const image = document.getElementById("target2");
           image.src = url;
  
          })
        })
      }  
    }

    
    onImageChange3 = (e) => {
      const file = this.fileUpload3.files[0];
      this.setState({image3:file})
      if(file){
      
        const storageRef = firebase.storage().ref();
        const mainImage = storageRef.child(file.name);          
           mainImage.put(file).then((snapshot) => {
            mainImage.getDownloadURL().then((url) =>{
             this.setState({imageURL3:url});
             const image = document.getElementById("target3");
             image.src = url;
    
            })
          })
        }
      }
    


  onSubmit = (e) => {

    
    let tempIndex=-1;
    for(var i =0;i<this.state.allSites.length;i++){
      if(this.state.allSites[i].id===this.state.currentID){
        tempIndex=i;
        break;
      }
    }
    
    if(tempIndex!==-1 && this.state.allSites[tempIndex].id!==this.state.largestID){
      let tempAllSites = this.state.allSites;
      let cameras = [];
      let teamMembers=[];
      if(this.state.mainCamera!==''){
        //If there's a mainCamera add it
        cameras.push(this.state.mainCamera);
        if(this.state.secondaryCamera!==''){
          //Additionally,if there's a secondaryCamera, add it
          cameras.push(this.state.secondaryCamera)
        }
      }
      if(this.state.teamMemberOne!==''){
        //If team member one exists, add them
        teamMembers.push(this.state.teamMemberOne);
        if(this.state.teamMemberTwo!==''){
          //Additionally, if team member two exists, add them
          teamMembers.push(this.state.teamMemberTwo);
          if(this.state.teamMemberThree!==''){
            //Additionally, if team member three exists, add them
            teamMembers.push(this.state.teamMemberThree);
            if(this.state.teamMemberFour!==''){
              //Additionally, if team member four exists, add them
              teamMembers.push(this.state.teamMemberFour);
            }
          }
        }
      }
      const db = firebase.firestore();
                        db.collection(collection).where("title","==",this.state.title).get().then(snapshot=>{
                          snapshot.forEach(doc=>{
                            var o={};
                            o.h1 = this.state.h1;
                            o.h2 = this.state.h2;
                            o.h3 = this.state.h3;
                            o.latestPic = this.state.latestPic;
                            o.projectDesc = this.state.projectDesc;
                            o.redStyle = this.state.redStyle;
                            o.s1 = this.state.s1;
                            o.s2 = this.state.s2;
                            o.s3 = this.state.s3;
                            o.title = this.state.title;
                            o.recentPicsLink = this.state.recentPicsLink;
                            o.dropboxLink=this.state.dropboxLink                            //test
                            // o.pic1 = this.state.imageURL1;
                            // o.pic2 = this.state.imageURL2;
                            // o.pic3 = this.state.imageURL3;
                            //check if the image has changed
                            if(this.state.imageURL1 !== ""){
                              o.pic1 = this.state.imageURL1;
                            }else{
                            o.pic1 = this.state.pic1
                            }
                            if(this.state.imageURL2 !== ""){
                            o.pic2 = this.state.imageURL2;
                            }else{
                              o.pic2 = this.state.pic2;
                            }
                            if(this.state.imageURL3 !== ""){
                              o.pic3 = this.state.imageURL3;
                            }else{
                              o.pic3 = this.state.pic3
                            }
                            o.cameras=cameras;
                            o.teamMembers=teamMembers;

                            db.collection(collection).doc(doc.id).update(o);
                            })
                       
                          })

      this.setState({
        allSites : tempAllSites
      });
      e.preventDefault();
      window.alert("Site updated")
    } else if(this.state.allSites[tempIndex].id===this.state.largestID){
      let camerasTemp = [];
      let teamMembersTemp=[];
      if(this.state.mainCamera!==''){
        //If there's a mainCamera add it
        camerasTemp.push(this.state.mainCamera);
        if(this.state.secondaryCamera!==''){
          //Additionally,if there's a secondaryCamera, add it
          camerasTemp.push(this.state.secondaryCamera)
        }
      }
      if(this.state.teamMemberOne!==''){
        //If team member one exists, add them
        teamMembersTemp.push(this.state.teamMemberOne);
        if(this.state.teamMemberTwo!==''){
          //Additionally, if team member two exists, add them
          teamMembersTemp.push(this.state.teamMemberTwo);
          if(this.state.teamMemberThree!==''){
            //Additionally, if team member three exists, add them
            teamMembersTemp.push(this.state.teamMemberThree);
            if(this.state.teamMemberFour!==''){
              //Additionally, if team member four exists, add them
              teamMembersTemp.push(this.state.teamMemberFour);
            }
          }
        }
      }
      const db = firebase.firestore();
      db.collection(collection).add({
        h1:this.state.h1,
        h2:this.state.h2,
        h3:this.state.h3,
        latestPic:this.state.latestPic,
        projectDesc:this.state.projectDesc,
        redStyle:this.state.redStyle,
        s1:this.state.s1,
        s2:this.state.s2,
        s3:this.state.s3,
        title:this.state.title,
        recentPicsLink:this.state.recentPicsLink,
        dropboxLink:this.state.dropboxLink,
        city:this.state.city,
        pic1:this.state.imageURL1,
        pic2:this.state.imageURL2,
        pic3:this.state.imageURL3,
        cameras:camerasTemp,
        teamMembers:teamMembersTemp,
        id:this.state.largestID
      })
      //e.preventDefault();
      window.alert("Site created")
    }
    e.preventDefault();
  }


  updateFields(id){
    let tempIndex =-1;
    for(var i=0;i<this.state.allSites.length;i++){
      if(this.state.allSites[i].id===id){
        tempIndex=i;
        break;
      }
    }
    let tempUpdateText='Update'
    if(tempIndex!==-1){
      if(this.state.allSites[tempIndex].id===this.state.largestID){
        tempUpdateText='Create'
      }
      this.fileUpload1.value = "";
      this.fileUpload2.value = "";
      this.fileUpload3.value = "";
      var site = this.state.allSites[tempIndex];
      var tempMainCamera='';
      var tempSecondaryCamera='';
      var tempTeamMemberOne='';
      var tempTeamMemberTwo='';
      var tempTeamMemberThree='';
      var tempTeamMemberFour='';
      if (site.cameras.length>0){
        //if there's at least 1 camera in the array, set the main camera
        tempMainCamera=site.cameras[0];
        if(site.cameras.length>1){
          //if there's at least 2 cameras in the array, set the secondarycamera
          tempSecondaryCamera=site.cameras[1];
        }
      }
      if(site.teamMembers.length>0){
        //if there's at least 1 team member in the area, set team member one
        tempTeamMemberOne=site.teamMembers[0];
        if(site.teamMembers.length>1){
          //Additionally, if there's at least 2 team members in the area, set team member two
          tempTeamMemberTwo=site.teamMembers[1];
          if(site.teamMembers.length>2){
            //Additionally, if there's at least 3 team members in the area, set team member three
            tempTeamMemberThree=site.teamMembers[2];
            if(site.teamMembers.length>3){
              //Additionally, if there's at least 4 team members in the area, set team member four
              tempTeamMemberFour=site.teamMembers[3];
            }
          }
        }
      }
      this.setState({
        boldText: site.boldText,
        cameras: site.cameras,
        mainCamera: tempMainCamera,
        secondaryCamera: tempSecondaryCamera,
        city: site.city,
        dropboxLink: site.dropboxLink,
        h1: site.h1,
        h2: site.h2,
        h3: site.h3,
        id: site.id,
        latestPic: site.latestPic,
        pic1: site.pic1,
        pic2: site.pic2,
        pic3: site.pic3,
        projectDesc: site.projectDesc,
        redStyle: site.redStyle,
        s1: site.s1,
        s2: site.s2,
        s3: site.s3,
        teamMembers: site.teamMembers,
        teamMemberOne: tempTeamMemberOne,
        teamMemberTwo: tempTeamMemberTwo,
        teamMemberThree: tempTeamMemberThree,
        teamMemberFour: tempTeamMemberFour,
        recentPicsLink: site.recentPicsLink,
        title: site.title,
        currentID: site.id,
        updateText: tempUpdateText
      })
    }
  }

  handlePasswordReset(){
    var auth = firebase.auth();
    auth.sendPasswordResetEmail(this.state.email).then(function() {
    alert("Email sent")
    }).catch(function(error) {
    // An error happened.
    });
  }

  deleteSites() {
    const db = firebase.firestore();
    db.collection(collection).where("boldText","==",this.state.boldText).get().then(snapshot=>{
      snapshot.forEach(doc=>{
        db.collection('SiteDetails').doc(doc.id).delete();
        })
      })

      }


  render() {
    if (this.state.sitesAreResolving) { return null } else {


      return (


        <div id='adminContainerMS'>


          <div className="container">
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                { <Select options={this.state.allSites.map((site) => {
                  return ({ label: site.city+" - "+site.title, value: site.id })
                })} onChange={(opt, meta) => {
                  this.updateFields(opt.value);
                  return true;
                }
                } placeholder="Select Site" styles={colourStyles} id="selectContainer"/> }
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>



          <div id="editContainerMS" className='container white'>
            <Header style="wlLogoNonAlt" color="white-text" />
            <div class="row">
              <div class="col2 s12 m6">
                <div class="card light-grey darken-1 z-depth-4">
                  <div class="card-content" id="adminCon">
                    <Title color="titleW" title="MANAGE SITES" />





                    <form onSubmit={this.onSubmit}>
                      <label>
                        <span className="labelText">Title Above Camera (required)</span>     
                        <input
                          class="input-field white-text"
                          id="targetTitle"
                          value={this.state.title}
                          onChange={event => this.setState(updateByPropertyName('title', event.target.value))}
                          type="text"
                          placeholder="ex. AUSTIN MARRIOTT"
                        />
                      </label>
                      <div>
                        <label>
                          <span className="labelText">City Name (required)</span>
                          <input
                            class="input-field white-text"
                            value={this.state.city}
                            onChange={event => this.setState(updateByPropertyName('city', event.target.value ))}
                            type="text"
                            placeholder="ex. AUSTIN"
                          />
                        </label>
                      </div>
                      <div>
                        <label>
                          <span className="labelText">Header 1 (Max Characters: 10)</span>
                          <input
                            className="input-field white-text"
                            value={this.state.h1}
                            onChange={event => this.setState(updateByPropertyName('h1', event.target.value))}
                            type="text"
                            placeholder="ex. 613"
                          />
                        </label>
                      </div>
                      <div>
                        <label>
                          <span className="labelText">Sub-Header 1 (Max Characters: 15)</span>
                          <input
                            className="input-field white-text"
                            value={this.state.s1}
                            onChange={event => this.setState(updateByPropertyName('s1', event.target.value))}
                            type="text"
                            placeholder="ex. ROOMS"
                          />
                        </label>
                      </div>
                      <div>
                        <label>
                          <span className="labelText">Header 2 (Max Characters: 10)</span>
                          <input
                            className="input-field white-text"
                            value={this.state.h2}
                            onChange={event => this.setState(updateByPropertyName('h2', event.target.value))}
                            type="text"
                            placeholder="ex. 15,212"
                          />
                        </label>
                      </div>
                      <div>
                        <label>
                          <span className="labelText">"Sub-Header 2 (Max Characters: 15)</span>
                          <input
                            className="input-field white-text"
                            value={this.state.s2}
                            onChange={event => this.setState(updateByPropertyName('s2', event.target.value))}
                            type="text"
                            placeholder="ex SF/MEETINGS"
                          />
                        </label>
                      </div>
                      <div>
                        <label>
                          <span className="labelText">City, State</span>
                          <input
                            class="input-field white-text"
                            value={this.state.boldText}
                            onChange={event => this.setState(updateByPropertyName('boldText', event.target.value ))}
                            type="text"
                            placeholder="ex. AUSTIN, TEXAS |"
                          />
                        </label>
                      </div>
                      <div>
                        <label>
                          <span className="labelText">Paragraph Red Callout</span>
                          <input
                            class="input-field white-text"
                            value={this.state.redStyle}
                            onChange={event => this.setState(updateByPropertyName('redStyle', event.target.value ))}
                            type="text"
                            placeholder="ex. OPENING SUMMER 2020"
                          />
                        </label>
                      </div>
                      <div>
                        <label>
                          <span className="labelText">Recent Pics URL</span>
                        </label>
                      </div>
                      <input
                        class="input-field white-text"
                        value={this.state.recentPicsLink}
                        onChange={event => this.setState(updateByPropertyName('recentPicsLink', event.target.value ))}
                        type="text"
                        placeholder="ex. https://www.dropbox.com/sh/1scka9rdvl7fxx7/AADYcky_cYaGRvZEJfnoG2vma?dl=0"
                        />
                      <div>
                        <label>
                          <span className="labelText">Dropbox URL</span>
                        </label>
                      </div>
                      <input
                        class="input-field white-text"
                        value={this.state.dropboxLink}
                        onChange={event => this.setState(updateByPropertyName('dropboxLink', event.target.value ))}
                        type="text"
                        placeholder="ex. https://www.dropbox.com/sh/xa20a33dwbm9z8n/AABosQCHV5LJ2u7VlzEyXKJya?dl=0"
                      />
                      <div>
                        <label>
                          <span className="labelText">Site Description</span>
                        </label>
                      </div>
                      <textarea
                        className="input-field white-text"
                        rows="15"
                        cols="30"
                        value={this.state.projectDesc}
                        onChange={event => this.setState(updateByPropertyName('projectDesc', event.target.value))}
                        type="text"
                        placeholder="DESCRIPTION (Place Holder)"
                        id="descBox" 
                      />
                      <div>
                        <label>
                          <span className="labelText">Picture 1 (Suggested Size: 635x635)</span>
                        </label>
                      </div>
                      <div className="imageEdit">
                          <img src={this.state.pic1} id="target1"  className="imgInputs"/> 
                          <input type="file" onChange={this.onImageChange1} className="filetype" id="pic1input" className="inputEdit"  ref={(ref) => this.fileUpload1= ref}/>
                      </div>
                      <div>
                        <label>
                          <span className="labelText">Picture 2 (Suggested Size: 635x635)</span>
                        </label>
                      </div>
                      <div  className="imageEdit">
                        <img src={this.state.pic2} id="target2"  className="imgInputs"/> 
                        <input type="file" onChange={this.onImageChange2} className="filetype" id="pic2input" className="inputEdit"  ref={(ref) => this.fileUpload2 = ref}/>
                      </div>
                      <div>
                        <label>
                          <span className="labelText">Picture 3 (Suggested Size: 635x635)</span>
                        </label>
                      </div>
                      <div  className="imageEdit">
                        <img src={this.state.pic3} id="target3"  className="imgInputs"/> 
                        <input type="file" onChange={this.onImageChange3} className="filetype" id="pic3input" className="inputEdit"  ref={(ref) => this.fileUpload3= ref}/>
                      </div>
                      <div>
                        <label>
                          <span className="labelText">Main Camera</span>
                        </label>
                      </div>
                      <input 
                        className="input-field white-text gray lighten-2 "
                        value={this.state.mainCamera}
                        onChange={event => this.setState(updateByPropertyName('mainCamera', event.target.value))}
                        type="text"
                        placeholder="ex. https://www.senserasystems.com/public/embed/M88921478165"
                      />
                      <div>
                        <label>
                          <span className="labelText">Secondary Camera</span>
                        </label>
                      </div>
                      <input 
                        className="input-field white-text gray lighten-2 "
                        value={this.state.secondaryCamera}
                        onChange={event => this.setState(updateByPropertyName('secondaryCamera', event.target.value))}
                        type="text"
                        placeholder="ex. https://www.senserasystems.com/public/embed/M88921478165"
                      />
                    <div id="projectTeam">
                    <div>
                      <label>
                        <span className="labelText">Project Team - First Row</span>
                      </label>
                    </div>
                    <input 
                      className="input-field white-text gray lighten-2 "
                      value={this.state.teamMemberOne}
                      onChange={event => this.setState(updateByPropertyName('teamMemberOne', event.target.value))}
                      type="text"
                      placeholder="First Project Team"
                    />
                    <div>
                      <label>
                        <span className="labelText">Project Team - Second Row</span>
                      </label>
                    </div>
                    <input 
                      className="input-field white-text gray lighten-2 "
                      value={this.state.teamMemberTwo}
                      onChange={event => this.setState(updateByPropertyName('teamMemberTwo', event.target.value))}
                      type="text"
                      placeholder="Second Project Team"
                    />
                    <div>
                      <label>
                        <span className="labelText">Project Team - Third Row</span>
                      </label>
                    </div>
                    <input 
                      className="input-field white-text gray lighten-2 "
                      value={this.state.teamMemberThree}
                      onChange={event => this.setState(updateByPropertyName('teamMemberThree', event.target.value))}
                      type="text"
                      placeholder="Third Project Team"
                    />
                    <div>
                      <label>
                        <span className="labelText">Project Team - Fourth Row</span>
                      </label>
                    </div>
                    <input 
                      className="input-field white-text gray lighten-2 "
                      value={this.state.teamMemberFour}
                      onChange={event => this.setState(updateByPropertyName('teamMemberFour', event.target.value))}
                      type="text"
                      placeholder="Fourth Project Team"
                    />
                    </div>


                    <div id="update" style={{display : 'flex', justifyContent : 'space-around'}}>
                      <button className ='btn pink lighten-1 z-depth-0' type="submit" >
                     {this.state.updateText}</button>

          
                     </div>
                      {this.state.error && <p>{this.state.error.message}</p>}

                    </form>




                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>


      )
    }
  }

}

//sorts sites array
function compare(a,b) {
  if(a.city<b.city) return -1;
  if(a.city>b.city) return 1;
  if(a.city===b.city){
    if(a.title<b.title) return -1;
    if(a.title>b.title) return 1;
  }
  return 0;
}


const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(manageSites);


