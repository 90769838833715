import React,{Component }  from 'react';
 import { Link } from 'react-router-dom';
 import './navigationAdmin.css';
 import { auth } from '../../firebase';
 import * as routes from '../../constants/routes';
 import firebase from '../../firebase/firebase';



class NavigationAdmin extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleOffClick = this.handleOffClick.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = {
        adminValue : false,
        userId : "default",
          showMenu : false,
          allUsers:[],
          error:null,
          userId:firebase.auth().currentUser.uid
        };



        const db = firebase.firestore();
        
    db.settings({
      // timestampsInSnapshots: true
    });

    db.collection('Users').get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          this.state.allUsers.push(doc.data());
        });
      }).catch(error => {
        alert(error);
      })


    };

    

    handleClose() {
      this.setState({ showMenu: false });
    }
  
    handleShow() {
      this.setState({ showMenu: true });
    }


    handleOffClick() {
      this.handleClose();
    }
    

    renderBackdrop(props) {
      return <div {...props} className="backdropStyle"/>;
    }

    menuToggle=()=>{
    
      this.setState({showMenu:!this.state.showMenu
      })

      if(this.state.showMenu === true){}
    }

    setWrapperRef(node){
      this.wrapperRef = node;
    }

    handleClickOutside(e){
      if(this.wrapperRef && !this.wrapperRef.contains(e.target) && !(e.target.tagName === 'A') && !(e.target.tagName === 'LI')){
        this.handleClose(); 
      }

  }



    componentWillMount(){
      document.addEventListener('mousedown',this.handleClickOutside);
      //document.addEventListener('mouseup',this.handleClickNav)
    }

   

    render() {
     const ui = this.state.userId

      if(ui === '8UoCApsB2GYIVsbSbzB0sF2tpFa2'|| ui === 'RwLlFDFTS6MGYXRPavj9PDN1xO32' || 
      ui=== 'My7tTAat61dH4TImmx9oz5CPhh53' /* || ui === 'alex put your uid here'*/)
        {
          try{
          
            return (
            <div className="nav" id="nav-container" >
              <Link to={routes.HOME}>
                <svg id="logo-mark" viewBox="0 0 31.39 27.95" width="100%" height="100%">
                  <path d="M0,0,0,22.1c.22,6.24,6.12,5.09,6.12,5.09L6.12,4C5-.77,0,0,0,0ZM9.56,0l0,22.65c.22,6.39,6.12,5.22,6.12,5.22l0-23.77C14.53-.78,9.56,0,9.56,0ZM31.35,21.73l-6.1,0L25.23,0s-5-.78-6.11,4.06V15.72l0,12.24h10C31.87,27.73,31.35,21.73,31.35,21.73Z"></path>
                </svg>
              </Link>
              
            
              <div className="tap-target" data-target="lc">
                <div className="tap-target-content">
                  <h5>Title</h5>
                  <p>A bunch of text</p>
                </div>
              </div>

              <div id="lc" ref={this.setWrapperRef} onClick={()=>this.menuToggle()}>
                  <div id="l1"></div>
                  <div id="l2"></div>
                  <div id="l3"></div>
              </div>

                {
                  //shows menu options or nothing
                  this.state.showMenu?
                    <div  id="menuBox" className="z-depth-3">
                  <div id='closeMenuBox' className><i className="material-icons md-36 white-text">close</i></div>
                  <ul id='menuList'>
                    <li onClick={this.handleClose} className="navi" id='homeLi'><Link to={routes.HOME}>HOME</Link></li>
                    <li onClick={this.handleClose} className="navi"><Link to={routes.MANAGE_USERS}>MANAGE USERS</Link></li>
                    <li onClick={this.handleClose} className="navi"><Link to={routes.MANAGE_SITES}>MANAGE SITES</Link></li> 
                    <li onClick={this.handleClose} className="navi"><Link to={routes.PASSWORD_FORGET}>RESET PASSWORD</Link></li>
                    <li onClick={this.handleClose} className="navi" id='signOutLi' onClick={auth.doSignOut}><Link to={routes.HOME}>SIGN OUT</Link></li>
                    
                  </ul>
                  </div>
                  :null
                }
              </div>
            )
          }catch(e){
            console.log(e)
          }
        }else{ //Return if user is not Admin

        return (
          <div className="nav" id="nav-container" >
            <Link to={routes.HOME}>
              <svg id="logo-mark" viewBox="0 0 31.39 27.95" width="100%" height="100%">
                <path d="M0,0,0,22.1c.22,6.24,6.12,5.09,6.12,5.09L6.12,4C5-.77,0,0,0,0ZM9.56,0l0,22.65c.22,6.39,6.12,5.22,6.12,5.22l0-23.77C14.53-.78,9.56,0,9.56,0ZM31.35,21.73l-6.1,0L25.23,0s-5-.78-6.11,4.06V15.72l0,12.24h10C31.87,27.73,31.35,21.73,31.35,21.73Z"></path>
              </svg>
            </Link>
            <div className="tap-target" data-target="lc">
              <div className="tap-target-content">
                <h5>Title</h5>
                <p>A bunch of text</p>
              </div>
            </div>

            <div id="lc" ref={this.setWrapperRef} onClick={()=>this.menuToggle()}>
                <div id="l1"></div>
                <div id="l2"></div>
                <div id="l3"></div>
            </div>
              
              {
                this.state.showMenu?
                  <div  id="menuBox" height="170px" className="z-depth-3">
                <div id='closeMenuBox' className><i className="material-icons md-36 white-text">close</i></div>
                <ul id='menuList'>
                  <li onClick={this.handleClose} className="navi" id='homeLi'><Link to={routes.HOME}>HOME</Link></li>
                  <li onClick={this.handleClose} className="navi"><Link to={routes.PASSWORD_FORGET}>RESET PASSWORD</Link></li>
                  <li onClick={this.handleClose} className="navi" id='signOutLi' onClick={auth.doSignOut}><Link to={routes.HOME}>SIGN OUT</Link></li>
                </ul>
              </div>
              :null
              }

            </div>
          )//end of return 
    }//end of else
  
  }
  
}


// We need an intermediary variable for handling the recursive nesting.



export default NavigationAdmin;
